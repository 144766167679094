.titleClass {
    font-family: 'Open Sans', sans-serif;
}

/**Header*/
.logo {
    width: 80%;
}

.logoDiv {
    margin-top: 20px;
}

.headerSeparator {
    height: 5px;
    background-color: #F6F6F6;
    border: none;
}

/**Multiselect*/
.multiselect {
    font-family: 'Montserrat', sans-serif;
    margin-top: 45px;
}

.searchBox {
    font-family: 'Montserrat', sans-serif;
}

/**Show List*/
.iconDiv {
    margin-bottom: 20px;
    color: #E21B54;
}

.iconDiv:hover {
    cursor: pointer;
}

.noResultsFound {
    margin-left: -240px;
    text-align: center;
    color: gray;
    padding-top: 100px;
}

.noResultsTitle {
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
}

.noResultsSubtitle {
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
}

.noResultsFound img {
    width: 20%;
}

/**Holiday Switches*/
.MuiFormControlLabel-root {
    margin-bottom: 15px;
}

.holidaySwitches {
    margin-top: 14px;
    margin-left: 14px;
    margin-bottom: 30px;
}

/**Cards*/
.MuiCardHeader-title {
    font-family: 'Open Sans', sans-serif;
}

.MuiCardHeader-subheader {
    font-family: 'Montserrat', sans-serif!important;
}

.MuiChip-label {
    font-family: 'Open Sans', sans-serif;
}

/**mobile styles*/
@media only screen and (max-width: 900px) {
    .logoDiv {
        text-align: center;
    }

    .multiselect {
        font-family: 'Montserrat', sans-serif;
        margin-top: 0px!important;
    }

    .noResultsFound {
        margin-left: 0px;
        text-align: center;
        color: gray;
        padding-top: 0px;
        margin-bottom: 20px;
        margin-top: -40px;
    }

    .noResultsTitle {
        font-size: 30px;
    }
    
    .noResultsSubtitle {
        font-size: 15px;
    }
  }